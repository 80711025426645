import React, { Component } from "react"

import SiteHeader from '../ui/SiteHeader'
import SiteFooter from '../ui/SiteFooter'
import SearchModal from "../components/SearchModal"

export default class RootElement extends Component {
  constructor(props) {
      super(props)
      this.state = {
          isSearchOpen: false,
      }
      this.handleSearchClose = this.handleSearchClose.bind(this)
      this.handleSearchOpen = this.handleSearchOpen.bind(this)
  }

  handleSearchClose() {
      this.setState({
          isSearchOpen: false,
      })
  }
  handleSearchOpen() {
      this.setState({
          isSearchOpen: true,
      })
  }
  render() {
    return (
        <>
            <SiteHeader openSearch={this.handleSearchOpen} />
            <main className="main">
                {this.props.children}
            </main>
            <SiteFooter />
        </>
    )
  }
}
