import React from 'react';
const SocialLinks = () => {
    if(process.env.GATSBY_OFFICE_NAME === 'WK_TOKYO'){
    return(
        <nav className="social-links">
            <a
                href="https://www.instagram.com/wktokyo/"
                target="_blank"
                rel="noopener noreferrer"
            >
                Instagram
            </a>
            <a
                href="https://www.facebook.com/wktokyo"
                target="_blank"
                rel="noopener noreferrer"
            >
                Facebook
            </a>
            <a
                href="https://twitter.com/wktokyo"
                target="_blank"
                rel="noopener noreferrer"
            >
                Twitter
            </a>
        </nav>
    );
    }
    else{
        return(
            <nav className="social-links">
                <a
                    href="https://www.instagram.com/wk.seoul/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Instagram
                </a>
            </nav>
        );
    }
}
export default SocialLinks;