import React from 'react'
import logo from "../medias/svg/wktokyo-logo.png"

class WKLogo extends React.Component{
    render(){
        let sitelogo
        if(process.env.GATSBY_OFFICE_NAME === 'WK_SEOUL'){
            return(
                <img src={logo} alt="" />
            );
        }
        else{
            return(
            <svg version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 106.7 48.2"
            enableBackground="new 0 0 106.7 48.2;">
            <path d="M103.8,5l-2.3,1.9h-8.4V5H103.8z M106.7,43.1l-6.8-6.8h-2.6l6.8,6.8H106.7z M77.9,36.3L72,42.2l-0.9,0.9h2.5l6.9-6.8
                L77.9,36.3z M71,43.1l-0.9-0.9l-5.9-5.9h-2.6l6.8,6.8H71z M80,14.3l-4.2,3.5v10.5v3.3h5.6h4.3v-1.9h-6.6h-1.3v-1.3V17.6v-1.3h1.3
                h19.6h1.3v1.3v12.1h-7.7l-4.1,3.5v12v3.1h1.9V32.9v-1.3h1.3h6.9l3.5-3v-11v-3.3h-3.3H80z M42.1,36.3l-6.8,6.8h2.5l6.9-6.8L42.1,36.3
                z M52,48.2h1.9V31.6L52,33.2C52,33.2,52,48.2,52,48.2z M53.9,14.3V8c-0.6,0-1.3,0-1.9,0v6.3h-8.3l-4.2,3.5v13.7h10.1l0-1.9h-8.2
                V16.3H52v5.4c0,0,0,0,0,0V22h-6.6v1.9H52v4.5c0.1,0,1.9,0,1.9,0v-4.5h4.9L61,22h-7.2v-5.7h10.5v13.4l-8.3,0l-2.3,1.9h8.5l3.9-3.3
                V14.3H53.9z M49.7,6.9L52,5l1.9-1.6V0H52v5H39.5v1.9H49.7z M88.3,3.7V5H87h-7.2h-5.2H59.3l-2.1,0v1.9l17.4,0h1.3h10.3l4.2-3.5V2V0
                h-1.9V3.7z M13.3,36.7l5.7-5.7h-2.3l0,0l-5.1,5.1V23.1v-0.2l-1.8,1.5v20.1l1.8-1.5v-5.7l2,2.1l1.3-1.1L13.3,36.7z M17.3,23.7h3.7
                l2.1-1.7h-3.7L17.3,23.7z M25.8,15.5h-0.1h-1.5h-0.1v0.1v5.5l1.7-1.5v-4L25.8,15.5L25.8,15.5z M24.1,24.6V30v0.1h0.1h1.5h0.1V30
                v-6.3h4.4l2-1.7h-5L24.1,24.6z M16.7,40.2l-1.3,1.1l2.7,2.8l0,0h0h2h0.3L20.3,44L16.7,40.2z M14.5,16.8L14.5,16.8l0.1,0.1h1.5h0.1
                l0-0.1l4.8-13l0.1-0.2l-2.7,2.3l-2.9,8.4l-1.6-4.6l-1.4,1.2L14.5,16.8z M4.9,16.8h1.7l4-11.1l0.9,2.4L12.9,7l-1.2-3.3l0-0.1h-0.1
                H9.8H9.7l0,0.1L5.8,14.4L2,3.7l0-0.1H1.9H0.2H0l0.1,0.2L4.9,16.8z"/>
            </svg>
            );
        }
    }
}

export default WKLogo